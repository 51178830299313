/* Google Font - Jost */
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@600&display=swap');

html {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
}

.ui-con {
  position: absolute;
  display: flex;
  flex-direction: column;

  padding: 15px;

  z-index: 20;
}

.controls-wrapper {
  min-width: 160px;
  max-width: 160px;

  margin-top: 20px;
  margin-bottom: 10px;

  border-radius: 10px;

  background-color: rgba(219, 219, 219, 0.9);

  font-family: 'Jost', sans-serif;

  padding: 10px;
}

.control-title {
  margin-top: 0px;
  margin-bottom: 5px;
  color: #333333;
  text-transform: uppercase;
}

.spacer {
  margin-top: 25px;
  margin-bottom: 25px;
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;

  height: 100vh;

  background-color: #4b36a8;
}

.load-text {
  text-transform: uppercase;
  font-family: 'Jost', sans-serif;

  color: #ffffff;
}

/* animated class */
.spinner {
  width: 200px;
  height: 200px;

  border: 16px solid #5c4ba7;
  border-radius: 50%;
  border-top: 16px solid #ffffff;

  animation: spin 0.5s ease-out infinite;
}

.control-button {
  color: white;

  opacity: 0.8;
}

.pause-play-button {
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  padding: 6px;

  height: 26px;
  width: 26px;
  cursor: pointer;
  border-radius: 200px;
  background-color: #5c4ba7;
}

.download-button {
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;

  padding: 6px;

  height: 26px;
  width: 26px;
  cursor: pointer;
  border-radius: 200px;
  background-color: #5c4ba7;
}

/* animations */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
